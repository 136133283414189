.face-element {
  border-style: solid;
  border-width: 5px;
  border-color: #cad2c5;
  border-radius: 30px;
  background-color: #354f52;
  filter: drop-shadow(0px 10px 10px #000);
}

.face-container-elements {
  display: flex;
  flex-direction: column;
  margin: 10px;

  &:hover {
    cursor: pointer;

    .face-image {
      transform: scale(1.1);
      filter: drop-shadow(0px 0px 10px #84a98c);
    }
  }
  
}

.face-image {
  height: 150px;
  width: 150px;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 0px;
  border-radius: 30px;
  

}

.text-container {
  text-align: center;
  margin: 5%;
  color: #cad2c5;

  .person-text {
    font-family: 'K2D', sans-serif;
    bottom: -30px;
    position: relative;
    
  }

  .sound-title-container {
    bottom: 30%;
  }

  .sound-title-text {
    font-family: 'K2D', sans-serif;
    border-bottom-style: solid;
    border-width: thin;
    margin-left: 10%;
    margin-right: 10%;
    bottom: 40px
  }
}
