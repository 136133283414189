.faces-list-container {
    padding-left: 5%;
    padding-right: 5%;
    background-color: #52796f;
    border-top: 4px;
    border-top-color: #cad2c5;
    border-top-style: solid ;
}

.home-header {
    text-align: center;
    background-color: #2f3e46 ;
    color: #cad2c5;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: 'Press Start 2P', cursive;
}

.footer-container {
    justify-content: center;
    align-self: center;
    margin-left: 2%
}

.grease-clan-text {
    color: #2f3e46;
}

